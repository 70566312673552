import { gql } from "@apollo/client";

export const GET_CURRENT_USER = gql`
    query currentUser {
        currentUser {
            id
            name
            email
            email_verified_at
            phone
            years_of_experience
            notice_period
            notice_period_status
            current_compensation
            expected_compensation
            current_compensation_text
            expected_compensation_text
            years_of_remote_experience
            async_video_intro_link
            english_proficiency_level
            availability_status
            preferred_pronoun
            heard_from
            number_of_completed_given_interviews
            education_details {
                id
                has_degree
                has_degree_in
                college
                start_period
                end_period
            }
            work_experiences {
                id
                company
                job_role
                start_period
                end_period
                description
                is_currently_working_here
            }
            is_email_change_requested
            resume_url
            resume_mime
            roles {
                id
                name
            }
            organization {
                id
                name
                country {
                    id
                    name
                }
            }
            skills {
                id
                name
                user_pivot {
                    years_of_experience
                    rating
                }
            }
            country {
                id
                name
                currency_suffix
            }
            city {
                id
                name
            }
            citizenship_country {
                id
                name
            }
            timezone
            notification_types {
                id
                event
                event_group
                pivot {
                    status
                }
            }
            application_uuids
        }
    }
`;

export const GET_CANDIDATE_STATISTICS = gql`
    query candidateStatistics {
        candidateStatistics {
            total_candidates
            new_candidates
            shortlisted_candidates
            hired_candidates
            rejected_candidates
            screened_candidates
            interviewed_candidates
            scheduled_candidates
        }
    }
`;

export const GET_ENGINEER_OFFER_STATISTICS = gql`
    query engineerOfferStatistics {
        engineerOfferStatistics {
            pending_offers
            rejected_offers
            accepted_offers
        }
    }
`;

export const GET_ENGINEER_USER_INTERVIEWS = gql`
    query getEngineerUser($id: ID!) {
        user(id: $id) {
            application_interviews {
                id
                duration
                scheduler {
                    id
                    organization {
                        id
                    }
                }
                interview_details {
                    insight_group_collection
                    recording_urls
                    scores
                    skill_scores
                    short_recording_urls
                    strengths
                    weaknesses
                    locked
                    details_pending
                    transcript_analysis
                    transcript_urls
                    recommendation
                    score
                    has_hire_recommendation
                    has_no_hire_recommendation
                }
                organization {
                    id
                    name
                }
                is_referred
                interviewer {
                    id
                    name
                    email
                    interview_count
                    organization {
                        id
                    }
                }
                participant {
                    id
                    name
                }
                start_time
                interview_type {
                    id
                    name
                }
                skills {
                    id
                    name
                    interview_pivot {
                        recommendation
                    }
                }
                started_at
                meeting_url
                invalidation_reasons
                is_published
            }
            application_openings {
                name
            }
        }
    }
`;

export const GET_CANDIDATE_SKILLS = gql`
    query getCandidate($uuid: String!) {
        candidate(uuid: $uuid) {
            id
            uuid
            skills {
                id
                name
                pivot {
                    years_of_experience
                }
            }
            certifications {
                id
                name
                pivot {
                    certification_url
                    comments
                }
            }
        }
    }
`;
export const GET_CANDIDATE = gql`
    query getCandidate($uuid: String!) {
        candidate(uuid: $uuid) {
            id
            uuid
            pii {
                name
                email
                phone
            }
            pii_docs {
                resume
                resume_url
                resume_mime
            }
            application_mode
            created_at
            years_of_experience
            years_of_remote_experience
            notice_period
            notice_period_status
            current_compensation
            expected_compensation
            current_compensation_text
            expected_compensation_text
            has_references_requested
            remaining_notice_period
            github_username
            preferred_cities {
                name
            }
            holding_compensation_text
            negotiable_compensation_text
            negotiable_compensation
            negotiable_compensation_status
            holding_compensation
            applied_for
            work_preferences
            challenging_work
            worklife_goals
            reason_for_change
            requires_unlocking
            gravatar
            screening_status
            screening_status_hint
            screening_notes
            last_screened_at
            rejection_reasons
            english_proficiency_level
            experience_areas
            work_place_preference
            last_working_day
            communication_screening
            education_details {
                id
                has_degree
                has_degree_in
                college
                start_period
                end_period
            }
            organization {
                id
            }
            previous {
                status
                all
            }
            next {
                status
                all
            }
            async_video_intro_link
            openings {
                name
            }
            city {
                id
                name
            }
            country {
                id
                name
                currency
            }
            interviews {
                id
                duration
                scheduler {
                    id
                    organization {
                        id
                    }
                }
                interview_details(candidate_uuid: $uuid) {
                    insight_group_collection
                    recording_urls
                    scores
                    skill_scores
                    short_recording_urls
                    strengths
                    weaknesses
                    locked
                    details_pending
                    transcript_analysis
                    transcript_urls
                    recommendation
                    score
                    has_hire_recommendation
                    has_no_hire_recommendation
                }
                organization {
                    id
                    name
                }
                is_referred
                interviewer {
                    id
                    name
                    email
                    interview_count
                    about_me
                    profile_picture
                    organization {
                        id
                    }
                }
                participant {
                    id
                    name
                }
                start_time
                interview_type {
                    id
                    name
                }
                skills {
                    id
                    name
                    interview_pivot {
                        recommendation
                    }
                }
                started_at
                meeting_url
                invalidation_reasons
            }
            skills {
                id
                name
                pivot {
                    years_of_experience
                }
            }
            meta {
                type
                key
                value
            }
            requirements {
                id
                name
            }
            feedbacks {
                id
                user {
                    id
                    name
                }
                requirement {
                    id
                    name
                }
                status
                comments
            }
            call_logs_around_screened_at {
                call_recording_url
            }
        }
    }
`;
export const GET_CANDIDATE_DETAILS = gql`
    query getCandidate($uuid: String!) {
        candidate(uuid: $uuid) {
            id
            pii {
                name
                email
                phone
            }
            pii_docs {
                resume
                resume_url
                resume_mime
            }
            uuid
            application_mode
            created_at
            years_of_experience
            years_of_remote_experience
            notice_period
            notice_period_status
            current_compensation
            expected_compensation
            current_compensation_text
            expected_compensation_text
            has_references_requested
            remaining_notice_period
            github_username
            linkedin_profile_url
            preferred_cities {
                name
            }
            holding_compensation_text
            negotiable_compensation_text
            negotiable_compensation
            negotiable_compensation_status
            holding_compensation
            applied_for
            work_preferences
            challenging_work
            worklife_goals
            reason_for_change
            requires_unlocking
            gravatar
            screening_status
            screening_status_hint
            screening_notes
            last_screened_at
            screened_by {
                name
            }
            rejection_reasons
            english_proficiency_level
            experience_areas
            work_place_preference
            last_working_day
            communication_screening
            internal_notes
            education_details {
                id
                has_degree
                has_degree_in
                college
                start_period
                end_period
            }
            organization {
                id
            }
            async_video_intro_link
            async_video_interviews {
                uuid
                title
                created_at
                link
            }
            openings {
                name
            }
            city {
                id
                name
            }
            country {
                id
                name
                currency
            }
            meta {
                type
                key
                value
            }
            feedbacks {
                id
                user {
                    id
                    name
                }
                requirement {
                    id
                    name
                    organization {
                        id
                        name
                    }
                }
                status
                comments
                amount
                notify_candidate
                country {
                    id
                    name
                    currency
                    currency_suffix
                }
            }
            requirements {
                id
                name
                organization {
                    id
                    name
                }
            }
            reviewers {
                id
                name
                email
            }
        }
    }
`;
export const GET_CANDIDATE_NEXT_PREVIOUS = gql`
    query getCandidate($uuid: String!) {
        candidate(uuid: $uuid) {
            id
            previous {
                status
                all
            }
            next {
                status
                all
            }
        }
    }
`;
export const GET_CANDIDATE_INTERVIEWS = gql`
    query getCandidate($uuid: String!) {
        candidate(uuid: $uuid) {
            interviews {
                uuid
                id
                duration
                scheduler {
                    name
                    id
                    organization {
                        id
                    }
                }
                interview_details(candidate_uuid: $uuid) {
                    insight_group_collection
                    recording_urls
                    scores
                    skill_scores
                    short_recording_urls
                    strengths
                    weaknesses
                    locked
                    details_pending
                    transcript_analysis
                    transcript_urls
                    recommendation
                    score
                    has_hire_recommendation
                    has_no_hire_recommendation
                    internal_notes
                }
                organization {
                    id
                    name
                }
                is_referred
                interviewer {
                    id
                    name
                    email
                    interview_count
                    about_me
                    profile_picture
                    organization {
                        id
                    }
                }
                participant {
                    id
                    name
                    email
                }
                start_time
                interview_type {
                    id
                    name
                }
                skills {
                    id
                    name
                    interview_pivot {
                        recommendation
                    }
                }
                started_at
                meeting_url
                invalidation_reasons
                is_private
            }
        }
    }
`;
export const GET_CANDIDATE_CALL_RECORD = gql`
    query getCandidate($uuid: String!) {
        candidate(uuid: $uuid) {
            call_logs_around_screened_at {
                call_recording_url
                start_time
            }
            whatsapp_bot_events {
                reduced_payload
                event_type
                created_at
            }
        }
    }
`;

export const GET_CANDIDATES = (
    searchName,
    searchEmail,
    filterNoticePeriod,
    filterSalary
) => gql`
    query getCandidates(
        $first: Int!
        $page: Int!
        $hasRequirement: ID
        $hasSkills: [ID]
        $screened: DateRangeInput
        $interviewed: DateRangeInput
        $statusFiltered: String!
        $application_mode: String
        $work_place_preference: [String]
        $notice_period_status: String
        ${filterNoticePeriod ? "$notice_period_start: Mixed" : ""}
        ${filterNoticePeriod ? "$notice_period_end: Mixed" : ""}
        ${filterSalary ? "$salary_start: Mixed" : ""}
        ${filterSalary ? "$salary_end: Mixed" : ""}
        $country_id: String
        $city_id: String
        ${searchName ? "$searchName: Mixed" : ""}
        ${searchEmail ? "$searchEmail: Mixed" : ""}
    ) {
        candidates(
            first: $first
            page: $page
            hasRequirement: $hasRequirement
            hasSkills: $hasSkills
            screened: $screened
            interviewed: $interviewed
            statusFiltered: $statusFiltered
            application_mode: $application_mode
            work_place_preference: $work_place_preference
            notice_period_status: $notice_period_status
            country_id: $country_id
            city_id: $city_id
            where: {
                OR: [
                    ${searchName
        ? "{ column: NAME, operator: ILIKE, value: $searchName }"
        : ""
    }
                    ${searchEmail
        ? "{ column: EMAIL, operator: ILIKE, value: $searchEmail }"
        : ""
    }

                ]
                AND: [
                    ${filterNoticePeriod
        ? "{ column: NOTICE_PERIOD, operator: GTE, value: $notice_period_start }, { column: NOTICE_PERIOD, operator: LTE, value: $notice_period_end }"
        : ""
    }
                    ${filterSalary
        ? "{ column: EXPECTED_COMPENSATION, operator: GTE, value: $salary_start }, { column: EXPECTED_COMPENSATION, operator: LTE, value: $salary_end }"
        : ""
    }
                ]
            }
        ) {
        data {
                id
                uuid
                pii {
                    name
                    email
                }
                pii_docs {
                    resume
                    resume_url
                    resume_mime
                }
                created_at
                years_of_experience
                notice_period
                notice_period_status
                current_compensation
                expected_compensation
                expected_compensation_text
                async_video_intro_link
                remaining_notice_period
                gravatar
                application_mode
                work_place_preference
                interviews {
                id
                start_time
                }
                feedbacks {
                    id
                    user {
                        id
                        name
                    }
                    requirement {
                        id
                        name
                        organization {
                        id
                        name
                    }
                    }
                    status
                    comments
                    notify_candidate
                    country {
                        id
                        name
                        currency
                        currency_suffix
                    }
                    amount
                }
                requirements {
                    id
                    name
                    organization {
                        id
                        name
                    }
                }
                organization {
                    id
                }
                city {
                    name
                }
                country {
                    name
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                total
            }
    }
}`;

export const GET_CANDIDATE_SECONDARY_DETAILS = gql`
    query candidateSecondaryDetails($uuids: Mixed) {
        candidateSecondaryDetails(
            where: { column: UUID, value: $uuids, operator: IN }
        ) {
            uuid
            skills {
                name
                pivot {
                    years_of_experience
                }
            }
            interviews_count
            requires_unlocking
            has_passed_screening
            has_failed_screening
            has_hire_recommendation
            has_no_hire_recommendation
            last_completed_at
        }
    }
`;

export const GET_ORGANIZATION_WITH_USERS = gql`
    query getOrganization($id: ID!) {
        organization(id: $id) {
            id
            name
            logo
            brand_color
            client_users {
                id
                name
                phone
                email
                is_active
                timezone
                profile_picture
                roles {
                    name
                }
            }
        }
    }
`;

export const GET_ALL_ORG_USERS_WITH_ROLE = gql`
    query allUsers($roles: [String], $organization_id: Mixed) {
        allUsers(
            hasRoles: $roles
            where: {
                column: ORGANIZATION_ID
                operator: EQ
                value: $organization_id
            }
        ) {
            id
            name
            email
            roles {
                id
            }
        }
    }
`;

export const GET_ALL_ORG_USERS_WITHOUT_ROLE = gql`
    query allUsers(
        $roles: [String]
        $organization_id: Mixed
        $is_unavailable_for_interviewing: Mixed
    ) {
        allUsers(
            hasRoles: $roles
            where: {
                AND: [
                    {
                        column: ORGANIZATION_ID
                        operator: EQ
                        value: $organization_id
                    }
                    {
                        column: IS_UNAVAILABLE_FOR_INTERVIEWING
                        operator: EQ
                        value: $is_unavailable_for_interviewing
                    }
                ]
            }
        ) {
            id
            name
            email
            roles {
                id
            }
        }
    }
`;

export const GET_ALL_ORG_USERS_WITHOUT_ROLE_SEARCHABLE = gql`
  query allUsers(
    $roles: [String]
    $organization_id: Mixed
    $is_unavailable_for_interviewing: Mixed
    $search: Mixed
  ) {
    allUsers(
      hasRoles: $roles
      where: {
        AND: [
          {
            column: ORGANIZATION_ID
            operator: EQ
            value: $organization_id
          }
          {
            column: IS_UNAVAILABLE_FOR_INTERVIEWING
            operator: EQ
            value: $is_unavailable_for_interviewing
          }
          {
            OR: [
              {
                column: NAME
                operator: LIKE
                value: $search
              }
              {
                column: EMAIL
                operator: LIKE
                value: $search
              }
            ]
          }
        ]
      }
    ) {
      id
      name
      email
      roles {
        id
      }
    }
  }
`;

export const GET_USERS_BY_IDS = gql`
  query allUsers($user_ids: Mixed!) {
    allUsers(
      where: {
        column: ID
        operator: IN
        value: $user_ids
      }
    ) {
      id
      name
      email
      roles {
        id
      }
    }
  }
`;


export const GET_ALL_USERS_WITHOUT_ROLE = gql`
    query allUsers(
        $roles: [String]
        $searchName: Mixed
        $searchEmail: Mixed
        $organizationId: Mixed
    ) {
        allUsers(
            hasRoles: $roles
            where: {
                AND: {
                    column: ORGANIZATION_ID
                    operator: EQ
                    value: $organizationId
                }
                OR: [
                    { column: NAME, operator: ILIKE, value: $searchName }
                    { column: EMAIL, operator: ILIKE, value: $searchEmail }
                ]
            }
        ) {
            id
            name
            email
            roles {
                id
            }
        }
    }
`;

export const GET_ORGANIZATION = gql`
    query organization($id: ID!) {
        organization(id: $id) {
            id
            name
            logo
            credits_remaining
            country {
                currency
                currency_suffix
                name
                id
            }
        }
    }
`;

export const GET_PUBLIC_ORGANIZATION = gql`
    query publicOrganization($referral_code: String) {
        publicOrganization(referral_code: $referral_code) {
            name
            logo
            brand_color
        }
    }
`;

export const ONBOARDING_STATUS = gql`
    mutation updateUserOnboarding($data: UserOnboardingInput!) {
        updateUserOnboarding(data: $data) {
            id
            is_onboarding_completed
        }
    }
`;

export const CREATE_REFERRED_CANDIDATE = gql`
    mutation storeReferredCandidate($data: CandidateInput!) {
        storeReferredCandidate(data: $data) {
            id
        }
    }
`;

export const EDIT_REFERRED_CANDIDATE = gql`
    mutation editReferredCandidate($data: CandidateInput!) {
        editReferredCandidate(data: $data) {
            id
            uuid
        }
    }
`;

export const CREATE_REQUIREMENT = gql`
    mutation storeRequirement($data: RequirementInput!) {
        storeRequirement(data: $data) {
            id
            name
            work_place
        }
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation changePasswordRequest($data: ChangePasswordInput!) {
        changePasswordRequest(data: $data)
    }
`;

export const EDIT_REQUIREMENT = gql`
    mutation editRequirement($data: RequirementInput!) {
        editRequirement(data: $data) {
            id
        }
    }
`;

export const TOGGLE_REQUIREMENT_ACTIVE_STATE = gql`
    mutation editRequirement($id: ID!, $is_active: Boolean!) {
        toggleRequirementActiveState(id: $id, is_active: $is_active) {
            id
            is_active
        }
    }
`;

export const APPLY_OPENING = gql`
    mutation applyOpening($requirement_id: ID!) {
        requirementApplication(requirement_id: $requirement_id) {
            id
            name
            has_logged_in_user_applied
        }
    }
`;

export const STORE_CANDIDATE_FEEDBACK_STATUS = gql`
    mutation storeCandidateFeedback($data: CandidateFeedbackInput!) {
        storeCandidateFeedback(data: $data) {
            status
            comments
        }
    }
`;

export const UPDATE_CANDIDATE = gql`
    mutation updateCandidate($id: ID!, $data: CandidateUpdateInput!) {
        updateCandidate(id: $id, data: $data) {
            id
            has_references_requested
        }
    }
`;

export const UPDATE_CANDIDATE_FEEDBACK_STATUS = gql`
    mutation updateCandidateFeedback(
        $id: ID!
        $data: CandidateFeedbackUpdateInput!
    ) {
        updateCandidateFeedback(id: $id, data: $data) {
            id
            comments
            status
            acceptance_status
        }
    }
`;

export const TOGGLE_USER_ACTIVE_STATE = gql`
    mutation updateUser($id: ID!, $data: UserUpdateInput!) {
        updateUser(id: $id, data: $data) {
            id
            is_active
        }
    }
`;

export const UNLOCK_CANDIDATE = gql`
    mutation unlockCandidate($candidate_uuid: String!) {
        unlockCandidate(candidate_uuid: $candidate_uuid) {
            candidate {
                id
                uuid
                pii {
                    name
                    email
                    phone
                }
                pii_docs {
                    resume
                    resume_url
                    resume_mime
                }
                created_at
                years_of_experience
                notice_period
                notice_period_status
                current_compensation
                expected_compensation
                remaining_notice_period
                applied_for
                work_preferences
                challenging_work
                worklife_goals
                reason_for_change
                requires_unlocking
                gravatar
                screening_status
                screening_notes
                rejection_reasons
                previous {
                    status
                    all
                }
                next {
                    status
                    all
                }
                async_video_intro_link
                openings {
                    name
                }
                city {
                    id
                    name
                }
                country {
                    id
                    name
                    currency
                }
                interviews {
                    id
                    duration
                    scheduler {
                        id
                        organization {
                            id
                        }
                    }
                    interview_details(candidate_uuid: $candidate_uuid) {
                        insight_group_collection
                        recording_urls
                        scores
                        skill_scores
                        short_recording_urls
                        strengths
                        weaknesses
                        locked
                        details_pending
                        transcript_analysis
                        transcript_urls
                        recommendation
                        has_hire_recommendation
                        has_no_hire_recommendation
                    }
                    interviewer {
                        id
                        name
                        email
                        interview_count
                        about_me
                        profile_picture
                        organization {
                            id
                        }
                    }
                    participant {
                        id
                        name
                    }
                    start_time
                    interview_type {
                        id
                        name
                    }
                    skills {
                        name
                    }
                    started_at
                    meeting_url
                    invalidation_reasons
                }
                skills {
                    id
                    name
                    pivot {
                        years_of_experience
                    }
                }
                meta {
                    type
                    key
                    value
                }
                requirements {
                    id
                    name
                }
                feedbacks {
                    user {
                        id
                        name
                    }
                    requirement {
                        id
                        name
                    }
                    status
                    comments
                }
            }
            organization {
                id
                name
                logo
                credits_remaining
            }
            response {
                success
                message
            }
        }
    }
`;

const userDataWithParsedResume = `
                id
                name
                email
                years_of_experience
                notice_period
                notice_period_status
                current_compensation
                expected_compensation
                resume_url
                resume_mime
                parsed_resume_details
                skills {
                    name
                    pivot {
                        years_of_experience
                    }
                }
            `;

export const UPDATE_USER = gql`
    mutation updateUser($id: ID!, $data: UserUpdateInput!) {
        updateUser(id: $id, data: $data) {
            id
            name
            email
            phone
            years_of_experience
            notice_period
            notice_period_status
            current_compensation
            expected_compensation
            english_proficiency_level
            availability_status
            preferred_pronoun
            resume_url
            resume_mime
            organization {
                id
                name
                country {
                    id
                    name
                }
            }
            skills {
                id
                name
                user_pivot {
                    years_of_experience
                    rating
                }
            }
            country {
                id
                name
            }
            city {
                id
                name
            }
            timezone
            is_email_change_requested
        }
    }
`;

export const UPDATE_ORGANIZATION = gql`
    mutation updateOrganization($id: ID!, $data: OrganizationUpdateInput!) {
        updateOrganization(id: $id, data: $data) {
            id
            name
            logo
            brand_color
        }
    }
`;

export const UPDATE_USER_WITH_PARSED_DETAILS = gql`
    mutation updateUser($id: ID!, $data: UserUpdateInput!) {
        updateUser(id: $id, data: $data) {
            ${userDataWithParsedResume}
        }
    }
`;

export const CREATE_USER = gql`
    mutation createUser($data: UserCreateInput!) {
        createUser(data: $data) {
            id
            name
        }
    }
`;

export const SHARE_ENGINEER_PROFILE = gql`
    mutation shareEngineerProfile($data: ShareEngineerProfileInput!) {
        shareEngineerProfile(data: $data) {
            link
        }
    }
`;

export const SAVE_SHARED_ENGINEER_PROFILE = gql`
    mutation saveSharedEngineerProfile($code: String!) {
        saveSharedEngineerProfile(code: $code) {
            id
            uuid
            skills {
                id
                name
            }
        }
    }
`;

const multipleRequirementData = `id
                uuid
                name
                open
                is_active
                organization {
                    name
                    logo
                }
                country {
                    name
                }
                city {
                    name
                }
                skills {
                    name
                    skill_pivot {
                        is_mandatory
                    }
                }
                is_public
                seniority
                has_logged_in_user_applied
                created_at
                all_candidates_count
                work_place`;

const singleRequirementData = `id
                uuid
                name
                open
                notice_period_preference
                is_active
                responsibilities
                qualifications
                budget_currency
                budget_from
                budget_to
                organization {
                    name
                    logo
                }
                country {
                    id
                    name
                }
                city {
                    id
                    name
                }
                skills {
                    id
                    name
                    skill_pivot {
                        is_mandatory
                    }
                }
                work_place
                is_public
                seniority
                has_logged_in_user_applied
                created_at
                all_candidates_count`;

const requirementData = `data {
                ${multipleRequirementData}
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                total
            }`;

export const GET_REQUIREMENT = gql`
    query getRequirement($uuid: String!) {
        requirement(uuid: $uuid) {
            ${singleRequirementData}
        }
    }
`;

export const GET_REQUIREMENTS_QUERY = (
    auth,
    countryFilter,
    countryFilterHasAnywhere,
    skillFilter,
    seniorityFilter,
    workPlaceFilter
) => {
    const queryName ="requirements";

    return gql`
        query getRequirements(
            $active: Boolean
            $is_public: Boolean
            $first: Int!
            $page: Int!
            $searchName: Mixed
            ${skillFilter ? "$skill_ids: Mixed" : ""}
            ${countryFilter ? "$country_ids: Mixed" : ""}
            ${seniorityFilter ? "$seniority: Mixed" : ""}
            ${workPlaceFilter ? "$work_places: Mixed" : ""}
        ) {
            ${queryName}(
            is_active: $active
            is_public: $is_public
            first: $first
            page: $page
            where: {
            AND: [
                { column: NAME, operator: ILIKE, value: $searchName }
                ${countryFilter
            ? countryFilterHasAnywhere
                ? "{ OR: [{ column: COUNTRY_ID, operator: IN, value: $country_ids }, { column: COUNTRY_ID, operator: IS_NULL }] }"
                : "{ column: COUNTRY_ID, operator: IN, value: $country_ids }"
            : ""
        }
                ${seniorityFilter
            ? "{ column: SENIORITY, operator: IN, value: $seniority }"
            : ""
        }
                ${workPlaceFilter
            ? "{ column: WORK_PLACE, operator: IN, value: $work_places }"
            : ""
        }
            ]
        }
            ${skillFilter
            ? "hasSkills: { column: ID, operator: IN, value: $skill_ids }"
            : ""
        }
            ) {
            ${requirementData}
        }
        }
    `;
};

export const GET_REQUIREMENTS_BY_ACTIVE_STATE = (searchName) => gql`
    query getRequirements(
        $active: Boolean
        $first: Int!
        $page: Int!
        $hasSkills: [ID]
        ${searchName ? "$searchName: Mixed" : ""}
    ) {
        requirements(
            is_active: $active
            first: $first
            page: $page
            hasSkills: $hasSkills
            where: {
                OR: [
                    ${searchName
        ? "{ column: NAME, operator: ILIKE, value: $searchName }"
        : ""
    }

                ]
            }
        ) {
            ${requirementData}
        }
    }
`;

export const GET_ALL_SKILLS = gql`
    query getAllSkills($allow_self_scheduling: Mixed) {
        allSkills(
            where: {
                AND: [
                    {
                        column: ALLOW_SELF_SCHEDULING
                        operator: IN
                        value: $allow_self_scheduling
                    }
                ]
            }
        ) {
            id
            name
        }
    }
`;

export const GET_ALL_REQUIREMENTS = (searchName) => gql`
    query getAllRequirements${searchName ? "($searchName: Mixed)" : ""} {
        allRequirements(
            where: {
                OR: [${searchName
        ? "{ column: NAME, operator: ILIKE, value: $searchName }"
        : ""
    }]
            }
        ) {
            id
            name
            skills {
                name
            }
            open
            country {
                name
            }
            city {
                name
            }
            organization {
                id
                name
            }
        }
    }
`;

export const GET_ALL_FILTERED_REQUIREMENTS = (search) => gql`
query getAllRequirements${search ? "($search: String)" : ""} {
        allRequirements
            ${search ? "(search: $search)" : ""}
          {
            id
            name
            is_active
            skills {
                name
            }
            open
            country {
                name
            }
            city {
                name
            }
            organization {
                id
                name
            }
        }
    }
`;

export const GET_ALL_CANDIDATE_REQUIREMENTS = (search) => gql`
query getAllRequirements${search ? "($search: String)" : ""} {
        allRequirements
            ${search ? "(search: $search)" : ""}
          {
            id
            name
            organization {
                id
                name
            }
        }
    }
`;

export const GET_ALL_ACTIVE_REQUIREMENTS = gql`
    query getAllActiveRequirements {
        allRequirements(is_active: true) {
            id
            name
        }
    }
`;

export const GET_ALL_COUNTRIES = gql`
    query getAllCountries {
        allCountries {
            id
            name
            currency
            currency_suffix
        }
    }
`;

export const GET_CITIES_VIA_COUNTRY = gql`
    query getCitiesViaCountry($id: ID!) {
        country(id: $id) {
            id
            name
            cities {
                id
                name
            }
        }
    }
`;

export const ORG_INTERVIEW_TYPES = gql`
    query interviewTypes(
        $allow_self_scheduling: Boolean
        $organization_id: String
    ) {
        orgInterviewTypes(
            allow_self_scheduling: $allow_self_scheduling
            organization_id: $organization_id
        ) {
            id
            name
        }
    }
`;

export const ORG_INTERVIEWER_SLOTS = gql`
    query orgInterviewSlots($date: Date!, $skills: [Int!]!) {
        orgInterviewSlots(date: $date, skills: $skills) {
            id
            user_id
            start
            end
            disabled
        }
    }
`;

export const SCHEDULE_INTERVIEW = gql`
    mutation scheduleInterview($data: InterviewInput!) {
        scheduleInterview(data: $data) {
            id
        }
    }
`;

export const SCHEDULE_CANDIDATE_INTERVIEW = gql`
    mutation scheduleCandidateInterview($data: InterviewInput!) {
        scheduleCandidateInterview(data: $data) {
            id
        }
    }
`;

export const RESCHEDULE_INTERVIEW = gql`
    mutation editInterview($id: ID!, $data: InterviewInput!) {
        editInterview(id: $id, data: $data) {
            id
            duration
            scheduler {
                id
                organization {
                    id
                }
            }
            interview_details {
                insight_group_collection
                recording_urls
                scores
                skill_scores
                short_recording_urls
                strengths
                weaknesses
                locked
                details_pending
                transcript_analysis
                transcript_urls
                recommendation
                score
                has_hire_recommendation
                has_no_hire_recommendation
            }
            interviewer {
                id
                name
                email
                interview_count
                organization {
                    id
                }
            }
            participant {
                id
                name
            }
            start_time
            interview_type {
                id
                name
            }
            skills {
                id
                name
            }
            started_at
            meeting_url
            invalidation_reasons
        }
    }
`;

export const REMOVE_RESUME = gql`
    mutation removeResume {
        removeResume {
            id
        }
    }
`;

export const SEND_EMAIL_VERIFICATION = gql`
    mutation sendVerificationEmail {
        sendVerificationEmail {
            id
        }
    }
`;

export const REQUEST_EMAIL_CHANGE = gql`
    mutation requestEmailChange($data: RequestEmailChangeInput!) {
        requestEmailChange(data: $data) {
            user {
                id
                is_email_change_requested
            }
            response {
                success
                message
            }
        }
    }
`;

export const GET_DASHBOARD_CANDIDATE_STATISTICS = gql`
    query getDashboardCandidateStatistics {
        candidateStatistics {
            total_candidates
            new_candidates
            shortlisted_candidates
            hired_candidates
            rejected_candidates
            screened_candidates
            interviewed_candidates
        }
    }
`;

export const GET_DASHBOARD_REQUIREMENTS_CHART_DATA = gql`
    query getDashboardRequirementsChartData($limit: Int, $is_active: Boolean) {
        limitedRequirements(
            limit: $limit
            is_active: $is_active
            hasCandidates: {}
        ) {
            id
            name
            is_active
            all_candidates_count
            new_candidates_count
            rejected_candidates_count
            shortlisted_candidates_count
            hired_candidates_count
            screened_candidates_count
            interviewed_candidates_count
        }
    }
`;

export const GET_DASHBOARD_REQUIREMENT_COUNT_DATA = gql`
    query getDashboardRequirementCountData {
        allRequirementsCount {
            active
            inactive
        }
    }
`;

export const GET_CREATE_REQUIREMENT_DATA = gql`
    query getCreateRequirementData {
        allSkills {
            id
            name
        }
        allCountries {
            id
            name
            currency
            currency_suffix
        }
    }
`;

export const GET_CANDIDATE_FEEDBACKS = gql`
    query getCandidateFeedbacks(
        $uuid: Mixed!
        $first: Int!
        $page: Int!
        $status: Mixed
        $acceptance_status: Mixed
    ) {
        candidateFeedbacks(
            hasCandidates: { column: UUID, operator: IN, value: $uuid }
            first: $first
            page: $page
            where: {
                AND: [
                    { column: STATUS, operator: EQ, value: $status }
                    {
                        column: ACCEPTANCE_STATUS
                        operator: IN
                        value: $acceptance_status
                    }
                ]
            }
        ) {
            data {
                id
                requirement {
                    name
                    organization {
                        name
                    }
                }
                status
                acceptance_status
                compensation_details_string
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                total
            }
        }
    }
`;

export const GET_EDIT_REQUIREMENT_DATA = gql`
    query getEditRequirementData($uuid: String!) {
        requirement(uuid: $uuid) {
            id
            name
            open
            notice_period_preference
            responsibilities
            qualifications
            notes_for_interviewers
            budget_currency
            budget_from
            budget_to
            is_active
            seniority
            work_place
            country {
                id
                name
                currency
                currency_suffix
            }
            city {
                id
                name
            }
            skills {
                id
                name
                skill_pivot {
                    is_mandatory
                }
            }
            is_public
            has_recommendations_enabled
            reviewers {
                id
                name
                email
            }
            organization {
                id
            }
        }
        allSkills {
            id
            name
        }
        allCountries {
            id
            name
            currency
            currency_suffix
        }
    }
`;

export const GET_CANDIDATE_LIST_DATA = (searchName, searchType) => gql`
    query getCandidateListData${searchName && searchType ? "($searchName: Mixed)" : ""
    } {
        allRequirements(
            where: {
                OR: [${searchName
        ? searchType === "ID"
            ? "{ column: ID, operator: ILIKE, value: $searchName }"
            : "{ column: NAME, operator: ILIKE, value: $searchName }"
        : ""
    }]
            }
        ) {
            id
            name
            organization {
                id
                name
            }
            skills {
                name
            }
            open
            country {
                name
            }
            city {
                name
            }
        }
    }
`;

export const GET_CANDIDATE_REQUIREMENT_LIST_DATA = (searchType, search) => gql`
    query getCandidateListData${search && searchType ? "($search: String)" : ""
    } {
        allRequirements
            ${search ? "(search: $search)" : ""}
         {
            id
            name
            organization {
                id
                name
            }
            skills {
                name
            }
            open
            country {
                name
            }
            city {
                name
            }
        }
    }
`;

export const GET_CANDIDATE_SKILL_DATA = gql`
    query getCandidateListData {
        allSkills {
            id
            name
        }
    }
`;

export const GET_CANDIDATE_STATIC_DATA = (isActive) => gql`
    query getCandidateCreateData {
        allCountries {
            id
            name
            currency
            currency_suffix
        }
        allRequirements${"(is_active: true)"} {
            id
            name
            skills {
                name
            }
            open
            country {
                name
            }
            city {
                name
            }
        }
        allSkills {
            id
            name
        }
        allIndustries {
            id
            name
        }
        candidateStaticOptions {
            notice_period_statuses
            workplace_preferences
            experience_areas
            communication
            compensation_suffix_frequencies
        }
    }
`;

export const GET_CANDIDATE_CREATE_DATA = gql`
    query getCandidateCreateData {
        allCountries {
            id
            name
            currency
            currency_suffix
        }
        allRequirements(is_active: true) {
            id
            name
            skills {
                name
            }
            open
            country {
                name
            }
            city {
                name
            }
        }
        allSkills {
            id
            name
        }
        allIndustries {
            id
            name
        }
        candidateStaticOptions {
            notice_period_statuses
            workplace_preferences
            experience_areas
            communication
            compensation_suffix_frequencies
        }
    }
`;

export const GET_CANDIDATE_EDIT_STATIC_DATA = gql`
    query getCandidateEditData {
        allCountries {
            id
            name
            currency
            currency_suffix
        }
        allRequirements {
            id
            name
            skills {
                name
            }
            open
            country {
                name
            }
            city {
                name
            }
        }
        allSkills {
            id
            name
        }
        allIndustries {
            id
            name
        }
        candidateStaticOptions {
            notice_period_statuses
            workplace_preferences
            experience_areas
            communication
            compensation_suffix_frequencies
        }
    }
`;

export const GET_CANDIDATE_EDIT_DATA = gql`
    query getCandidateEditData($uuid: String!) {
        candidate(uuid: $uuid) {
            id
            uuid
            pii {
                name
                email
                phone
            }
            pii_docs {
                resume
                resume_url
                resume_mime
            }
            application_mode
            created_at
            remaining_notice_period
            applied_for
            work_preferences
            challenging_work
            worklife_goals
            reason_for_change
            gravatar
            screening_status
            skip_screening
            city {
                id
                name
            }
            country {
                id
                name
                currency
            }
            requirements {
                id
                name
            }
            organization {
                id
            }
            years_of_experience
            years_of_remote_experience
            notice_period
            notice_period_status
            last_working_day
            current_compensation
            expected_compensation
            negotiable_compensation
            negotiable_compensation_status
            holding_compensation
            github_username
            work_place_preference
            experience_areas
            communication_screening
            screening_notes
            internal_notes
            next_available_at_from
            next_available_at_to
            current_compensation_frequency
            current_compensation_country_id
            expected_compensation_frequency
            expected_compensation_country_id
            skills {
                name
                id
                pivot {
                    years_of_experience
                }
            }
            industries {
                name
                id
                pivot {
                    years_of_experience
                }
            }
        }
    }
`;

export const GET_PROFILE_DATA = gql`
    query getProfileData {
        allCountries {
            id
            name
            currency
        }
        allSkills {
            id
            name
        }
    }
`;

export const VALIDATE_GOOGLE_TOKEN = gql`
    mutation validateGoogleToken($data: GoogleTokenPayload!) {
        validateGoogleToken(data: $data) {
            is_valid
            data
            user_has_account
            error
        }
    }
`;

export const GET_INTERVIEW_QUESTION = gql`
    query publicViewQuestion(
        $interview_uuid: String!
        $question_uuid: String!
    ) {
        publicViewQuestion(
            interview_uuid: $interview_uuid
            question_uuid: $question_uuid
        ) {
            interview {
                id
                interview_topic
                utc_start_time
            }
            expired
            question {
                id
                question
            }
        }
    }
`;

export const GET_CLIENT_NOTIFICATION_TYPES = gql`
    query clientNotificationTypes {
        clientNotificationTypes {
            id
            event
            event_group
        }
    }
`;

export const UPLOAD_AND_PARSE_RESUMES = gql`
    mutation uploadAndParseResumes($data: [String]!) {
        uploadAndParseResumes(data: $data) {
            name
            email
            phone
            resume
            resume_url
            resume_mime
        }
    }
`;

export const CREATE_BULK_CANDIDATES = gql`
    mutation createBulkCandidates($data: [BulkCandidateInput]!) {
        createBulkCandidates(data: $data) {
            id
        }
    }
`;

export const CLONE_REQUIREMENT = gql`
    mutation cloneRequirement($uuid: String!) {
        cloneRequirement(uuid: $uuid) {
            id
            name
            work_place
        }
    }
`;

export const GET_ALL_REQUIREMENT_COUNT = gql`
    query allRequirementsCount {
        allRequirementsCount {
            active
            inactive
        }
    }
`;

export const GET_INTERVIEW_DATA = gql`
    query interview($uuid: String!) {
        interview(uuid: $uuid) {
            data
        }
    }
`;

export const GET_CALENDAR_INTERVIEWS = gql`
    query calendarInterviews($hasSkills: [ID], $hasInterviewers: [ID], $dateRange: DateRangeInput) {
        calendarInterviews(hasSkills: $hasSkills, hasInterviewers: $hasInterviewers, dateRange: $dateRange) {
            id
            duration
            uuid
            interview_topic
            start_time
            meeting_url
            candidate {
                uuid
                pii {
                    name
                    email
                    phone
                }
            }
            interviewer {
                name
                linkedin_profile_url
                email
                phone
            }
            skills {
                id
                name
                pivot {
                    years_of_experience
                }
            }
        }
    }
`;

export const PUBLISH_QUESTION = gql`
    mutation publishQuestion($data: PublishQuestionInput!) {
        publishQuestion(data: $data)
    }
`;

export const UPDATE_INTERVIEW_DETAILS = gql`
    mutation updateInterviewDetails($data: UpdateInterviewDetails!) {
        updateInterviewDetails(data: $data)
    }
`;

export const DELETE_INTERVIEW_RECORDING = gql`
    mutation clearInterviewRecording($data: ClearInterviewRecording!) {
        clearInterviewRecording(data: $data)
    }
`;

export const GET_CANDIDATE_PII_DATA = gql`
    query getCandidate($uuid: String!) {
        candidate(uuid: $uuid) {
            id
            uuid
            pii {
                name
                email
                phone
            }
            organization {
                id
                name
            }
            pii_docs {
                resume
                resume_url
                resume_mime
            }
            github_username
            linkedin_profile_url
            requires_unlocking
            gravatar
            async_video_intro_link
            city {
                id
                name
            }
            country {
                id
                name
                currency
            }
        }
    }
`;

export const GET_QUESTIONS_LIST_DATA = gql`
    query getQuestionsListData {
        allSkills {
            id
            name
            questions_count
        }
    }
`;

export const GET_ALL_QUESTIONS = (
    skill_id,
    organization_id,
    question_group_id,
    is_published,
    difficulty
) => gql`
    query allQuestions(
        $first: Int!
        $page: Int!
        ${skill_id ? "$skill_id: ID" : ""}
        ${organization_id ? "$organization_id: ID" : ""}
        ${question_group_id ? "$question_group_id: ID" : ""}
        ${is_published ? "$is_published: Mixed" : ""}
        ${difficulty ? "$difficulty: QuestionDifficulty" : ""}
    ) {
        allQuestions(
            first: $first,
            page: $page,
            ${skill_id ? "skill_id: $skill_id" : ""},
            ${organization_id ? "organization_id: $organization_id" : ""},
            ${question_group_id ? "question_group_id: $question_group_id" : ""}
            ${is_published
        ? "is_published: {AND: {column: USER_ID, operator: EQ, value: $is_published}}"
        : ""
    }
            ${difficulty ? "difficulty: $difficulty" : ""}
        ) {
            data {
                id
                uuid
                question
                answer
                difficulty
                skill {
                    id
                    name
                }
                organization {
                    id
                }
                isPublished
                questionGroup {
                    id
                    name
                }
                insights {
                    id
                    name
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                total
            }
        }
    }
`;

export const GET_INSIGHTS_BY_QUESTION_TYPE = gql`
    query questionTypeInsights($question_type_id: ID) {
        questionTypeInsights(question_type_id: $question_type_id) {
            id
            name
            recommended
        }
    }
`;

export const GET_QUESTION_GROUPS_BY_QUESTION_SKILL = gql`
    query questionGroupsByQuestionSkill($skill_id: ID) {
        questionGroupsByQuestionSkill(skill_id: $skill_id) {
            id
            name
        }
    }
`;

export const SET_QUESTION_ASKED_AT = gql`
    mutation setAskedAt($data: SetAskedAtInput!) {
        setAskedAt(data: $data)
    }
`;

export const GET_ALL_INTERVIEW_TYPES = gql`
    query allInterviewTypes($organization_id: ID) {
        allInterviewTypes(organization_id: $organization_id) {
            id
            name
            insights_count
        }
    }
`;

export const GET_ALL_INSIGHTS_PAGINATED = (
    interview_type_id,
    insight_group_id,
    organization_id
) => gql`
    query paginatedInsights(
        $first: Int!
        $page: Int!
        ${interview_type_id ? "$interview_type_id: Mixed" : ""}
        ${insight_group_id ? "$insight_group_id: ID" : ""}
    ) {
        paginatedInsights(
            first: $first,
            page: $page,
            ${interview_type_id
        ? `interview_type_id: { AND: [{column: INTERVIEW_TYPES_ID, value: $interview_type_id}{column: ORGANIZATION_ID, value: ${organization_id}}] }`
        : ""
    },
            ${insight_group_id ? "insight_group_id: $insight_group_id" : ""}
        ) {
            data {
                id
                name
                is_active
                type
                description
                options {
                    id
                    value
                    description
                }
                interview_types {
                    id
                    name
                }
                insight_group {
                    id
                    name
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                total
            }
        }
    }
`;

export const GET_INSIGHT_GROUPS_BY_INTERVIEW_TYPE = gql`
    query insightGroupsByInterviewType($interview_type_id: ID) {
        insightGroupsByInterviewType(interview_type_id: $interview_type_id) {
            id
            name
        }
    }
`;

export const SHARE_CANDIDATE = gql`
    mutation shareCandidate($id: ID!, $data: ShareCandidateInput!) {
        shareCandidate(id: $id, data: $data)
    }
`;

export const ADD_QUESTION = gql`
    mutation createQuestion($data: QuestionInput!) {
        createQuestion(data: $data) {
            id
        }
    }
`;

export const EDIT_QUESTION = gql`
    mutation editQuestion($data: QuestionInput!) {
        editQuestion(data: $data) {
            id
        }
    }
`;

export const CLONE_QUESTION = gql`
    mutation cloneQuestion($id: String!) {
        cloneQuestion(id: $id) {
            id
        }
    }
`;

export const GET_QUESTION = gql`
    query question($id: ID) {
        question(id: $id) {
            id
            question
            answer
            organization {
                id
            }
            difficulty
            skill {
                id
                name
            }
            isPublished
            questionGroup {
                id
                name
            }
            insights {
                id
                name
            }
            questionType {
                id
            }
            is_active
            is_public
        }
    }
`;

export const GET_ASYNC_MEET_ACCESS = gql`
    query getAsyncMeetAccessToken(
        $api_key: String!
        $email: String!
        $organization_name: String!
    ) {
        getAsyncMeetAccessToken(
            api_key: $api_key
            email: $email
            organization_name: $organization_name
        ) {
            has_access
            token
        }
    }
`;
